import React, { useContext, useEffect, useState } from "react";

import "./QuestionsList.css";
import QuestionsAccordion from "../../../components/QuestionsAccordion";
import ChipButton from "../../../components/Buttons/ChipButton";

import { ReactComponent as GoogleIcon } from "../../../assets/icons/map/goggle.svg";
import { ReactComponent as YandexIcon } from "../../../assets/icons/map/yandex.svg";
import { ReactComponent as TwoGisIcon } from "../../../assets/icons/map/2gis.svg";
import { useNavigate } from "react-router-dom";
import { HISTORY_ROUTE } from "../../../utils/consts";
import { DataContext } from "../../../context/context";

const QuestionsList = () => {
  const navigate = useNavigate();

  const { clearData, chat, chatLoading, getChat } = useContext(DataContext);

  const handleOpenLink = (link) => {
    window.open(link);
  };

  const handleHelp = async (e) => {
    e.preventDefault();
    let link = chat;
    if (!chat) link = await getChat("headbtn");
    window.open(link?.chat_url || "https://t.me/mpstart");
  };

  const questions = [
    {
      title: "Какие переводы возможны",
      Content: (
        <div>
          <p>
            Инфраструктура MoneyPort — это десятки счетов юридических и
            физических лиц в банках и платёжных системах по всему миру + кассы с
            выдачей наличных.
          </p>
          <p>В MoneyPort возможны:</p>
          <ul>
            <li>
              Переводы физлицам на PayPal, Zelle, Wise, Revolut, Сбербанк,
              Тинькофф;
            </li>
            <li>
              Выставление или оплата инвойсов на компании в странах Европы, США,
              ОАЭ, Китае, Турции, Тайланде, Узбекистане, России и других;
            </li>
            <li>Прием и выдача наличных в 10 городах РФ и 35 городах мира;</li>
            <li>Криптовалютные переводы;</li>
            <li>Иные способы, уточняйте у менеджера.</li>
          </ul>
          <p>Например, вы можете:</p>
          <ul>
            <li>
              Пополнить счет наличными рублями в Москве и оплатить инвойс
              поставщика за товары в Европе, США, Китае, Японии и других
              странах;
            </li>
            <li>
              Пополнить счет переводом на Тинькофф или Сбербанк и вывести деньги
              на счет в Европейском банке;
            </li>
            <li>
              Пополнить счет наличными рублями в РФ и получить наличные доллары
              в США;
            </li>
            <li>
              Выставить инвойс зарубежной компании и вывести себе деньги в
              криптовалюте;
            </li>
            <li>
              Оплатить по контракту с компании в РФ и оплатить инвойс
              зарубежного поставщика со счета зарубежной компании.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Какие комиссии",
      Content: (
        <div>
          <p>
            Чтобы назвать конкретную комиссию, мы должны точно знать ответы на 4
            базовых вопроса: откуда, куда, сколько и какая регулярность?
          </p>
          <p>
            Комиссия за перевод зависит от направления платежа, суммы и
            регулярности. Переходите в чат-кассу с менеджером (все операции по
            переводу мы проводим в чат-кассе клиента), опишите вашу задачу и
            менеджер рассчитает курс перевода.
          </p>
        </div>
      ),
    },
    {
      title: "Какие гарантии",
      Content: (
        <div>
          <p>
            Мы даем 100% гарантию, что доставим ваши деньги до получателя. Также
            гарантируем, что все риски платежа берем на себя. Если в какой-то
            момент деньги «потеряются», то мы оплатим повторно или вернем вам
            всю сумму в полном размере.
          </p>
          <p>
            На каждую <b>1000</b> платежей
            <b> 998 доходят быстро и с первого раза</b> и только 2 платежа
            зависают и мы платим повторно. Такая статистика.
          </p>
        </div>
      ),
    },
    {
      title: "Почему можно доверять MoneyPort",
      Content: (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div>
            <p>
              Доверие к MoneyPort базируется на репутации. Люди выбирают
              MoneyPort из-за репутации, которая, в случае плохой работы,
              рушится мгновенно.
            </p>
            <p>
              Наша репутация безупречна, ежедневно мы проводим сотни переводов
              для разных людей. После сделок мы просим клиентов оставить отзыв.
            </p>
            <p>
              Посмотреть все отзывы можно в нашем{" "}
              <a
                href="https://t.me/+Pmg74tqAs1Q3ZGIy"
                rel="noreferrer"
                target="_blank"
              >
                телеграм-канале
              </a>{" "}
              по хештегу #отзывы. А также на:{" "}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <ChipButton
              text={"Google Карты"}
              icon={<GoogleIcon />}
              onClick={() =>
                handleOpenLink(
                  "https://www.google.ru/maps/place/Moneyport/@55.749734,37.5355733,17z/data=!4m6!3m5!1s0x46b5393b0a06535d:0xe180853218240595!8m2!3d55.749731!4d37.5381482!16s%2Fg%2F11stvh5dz7?entry=ttu"
                )
              }
            />
            <ChipButton
              text={"Яндекс Карты"}
              icon={<YandexIcon />}
              onClick={() =>
                handleOpenLink(
                  "https://yandex.ru/maps/org/moneyport/209315553724/?display-text=moneyport&ll=37.537434%2C55.749633&mode=search&sctx=ZAAAAAgBEAAaKAoSCZ5eKcsQz0JAEdOgaB7A4EtAEhIJ3gIJih8j9z8RUS%2F4NCcv3j8iBgABAgMEBSgKOABA1QFIAWoCcnWdAc3MTD2gAQCoAQC9AXW6szvCAQa8w7fhiwbqAQDyAQD4AQCCAgltb25leXBvcnSKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=37.537434%2C55.749633&sspn=0.022595%2C0.007370&text=moneyport&z=16"
                )
              }
            />
            <ChipButton
              text={"2ГИС"}
              icon={<TwoGisIcon />}
              onClick={() =>
                handleOpenLink(
                  "https://2gis.ru/moscow/firm/70000001075777302/tab/reviews"
                )
              }
            />
          </div>
          <div>
            <p>
              <b>Мы верифицированы BestChange</b>
            </p>
            <p>
              Мы представлены на крупнейшем мониторинге BestChange, попасть туда
              крайне сложно. Доверие со стороны администрации BestChange — уже
              гарантия безупречной репутации.
            </p>
            <a
              href="https://www.bestchange.ru/moneyport-exchanger.html"
              rel="noreferrer"
              target="_blank"
            >
              Открыть листинг
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "Какие сроки",
      Content: (
        <div>
          <p>
            После получения денег от вас мы отправляем платеж в течение суток,
            зачастую отправляем в течение нескольких часов.
          </p>
          <p>
            Сроки поступления средств зависят от банков. По нашему опыту SWIFT
            платежи доходят за 3-5 дней, но иногда сроки могут быть увеличены.
          </p>
          <p>
            Например, платежи в CNY могут обрабатываться дольше обычного, а
            отправка PayPal занимать до 7 дней.
          </p>
          <p>
            При задержках мы вас проинформируем заранее и сообщаем о сдвигах в
            исполнении.
          </p>
        </div>
      ),
    },
    {
      title: "Как происходит процесс перевода",
      Content: (
        <div>
          <p>
            Вы пополняете счет любым удобным способом как физлицо или как
            юрлицо:
          </p>
          <ul>
            <li>Переводом внутри банка или платёжной системы</li>
            <li>Наличными в офисе</li>
            <li>Криптовалютой</li>
          </ul>
          <p>
            Для пополнения счета доступно множество валют, подробнее какие
            валюты мы можем принять уточняйте у менеджера в конкретном
            направлении (страна отправления, способ отправления).
          </p>
          <p>
            После того как мы получили деньги от вас, операционист фиксирует
            курс сделки и переводит деньги на указанные вами реквизиты.
          </p>
          <p>
            Все коммуникация по переводам осуществляется в вашей личной
            чат-кассе. Чат-касса - это закрытая группа в Telegram с вами,
            менеджером и ботом Бухгалтером. Бот Бухгалтер подскажет актуальный
            курс и проведет необходимые операции с балансом.{" "}
          </p>
          <p>
            Вся история операций по балансу отображается в вашем личном кабинете
            на странице{" "}
            <span
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={() => navigate(HISTORY_ROUTE)}
            >
              История операций
            </span>
            . Также в чат-кассе доступны быстрые команды для просмотра баланса
            "/b" и истории операций "/h".
          </p>
        </div>
      ),
    },
    {
      title: "Лимиты по переводам",
      Content: (
        <div>
          <p>
            У нас есть лимиты на сумму перевода. Лимиты зависят от направления.
          </p>
          <p>Лимиты на переводы физлицам:</p>
          <ul>
            <li>перевод на физ. лицо в ЕС от 500 EUR</li>
            <li>перевод на физ. лицо в США от 500 USD</li>
            <li>перевод на физ. лицо в Китай от 30 000 CNY</li>
          </ul>
          <p>Лимиты на переводы юрлицам:</p>
          <ul>
            <li>перевод на юр. лицо в ЕС от 1 000 EUR</li>
            <li>перевод на юр. лицо в США от 3 000 USD</li>
            <li>перевод на юр. лицо в Китай от 50 000 CNY</li>
          </ul>
          <p>Лимиты на прием валютной выручки от иностранного контрагента:</p>
          <ul>
            <li>прием от физ. лица США от 1 000 USD</li>
            <li>прием от юр. лица США от 3 000 USD</li>
            <li>прием от физ. лица ЕС от 1 000 EUR</li>
            <li>прием от юр. лица ЕС от 3 000 EUR</li>
          </ul>
          <p>Лимиты на выдачу наличных:</p>
          <ul>
            <li>выдача RUB в РФ от 150 000 RUB</li>
            <li>выдача USD в РФ от 5 000 USD</li>
            <li>выдача EUR в РФ от 5 000 EUR</li>
            <li>выдача USD за рубежом от 10 000 - 20 000 USD</li>
            <li>выдача EUR за рубежом от 10 000 - 20 000 EUR</li>
          </ul>
          <p>
            Если не нашли своего направления в выше указанных, уточните лимиты
            по направлению у менеджера. Чтобы связаться с менеджером, нажмите{" "}
            <span
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={handleHelp}
            >
              "Поддержка"
            </span>
            .
          </p>
        </div>
      ),
    },
    {
      title: "Как получить оплату от иностранного контрагента",
      Content: (
        <div>
          <p>
            Возвращаем валютную выручку в Россию. Ваш контрагент переводит EUR
            или USD на наш зарубежный счет, а мы выплатим вам RUB в РФ от своего
            юр.лица из РФ.
          </p>
          <img
            style={{ width: "100%", margin: "8px 0", borderRadius: "16px" }}
            src="https://i.imgur.com/5wzxyEp.jpg"
            alt="usdeur"
          ></img>
          <p>
            Подойдет бизнесу, продающему товары или услуги в другие страны.
            Например:
          </p>
          <ul>
            <li>
              IT-специалистам, чтобы принимать оплату за разработку и продажу
              софта
            </li>
            <li>Производителям любых товаров</li>
            <li>Выводы с Google Adsense и других партнерских систем</li>{" "}
            <li>Экспортерам сельхозпродукции.</li>
          </ul>
          <p>
            Для обсуждения задачи и расчета комиссии по направлению переходите в{" "}
            <span
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={handleHelp}
            >
              чат с менеджером
            </span>
            .
          </p>
        </div>
      ),
    },
    {
      title: "Оплата инвойсов",
      Content: (
        <div>
          <p>
            <b>За что можем оплатить?</b>
            <p>
              Мы можем оплатить инвойс практически за любые товары и услуги: от
              контейнера зубочисток до запчастей на самолеты. У нас есть решения
              для всех видов бизнеса.
            </p>
          </p>
          <p>
            <b>Как мы оплачиваем инвойсы? </b>
            <p>Есть два варианта оплаты, они зависят от размера компаний.</p>
            <p>
              Оплата для небольших компаний. Вы нам платите наличные RUB / USD /
              EUR или если удобно криптовалюту (USDT, BTC, ETH), а мы оплачиваем
              USD / EUR / JPY и любую другую валюту вашим поставщикам с наших
              иностранных компаний. Мы можем платить со счетов наших компаний в
              Европе (Чехия, Италия и Германия), Гонконга, США, Дубая, стран
              Азии. Вариантов много и они зависят от конкретной задачи: куда
              платить, за что и какой объем. Подробнее по данному направлению
              уточняйте у менеджера. Связаться с менеджером можно, нажав{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={handleHelp}
              >
                "Поддержка"
              </span>
              .
            </p>
            <p>
              Для крупных компаний, которые ввозят товар с уплатой НДС и всех
              таможенных сборов. Вы оплачиваете по контракту RUB на компанию в
              Киргизии, мы конвертируем RUB в USD или EUR и дальше с этой
              компании оплачиваем вашим поставщикам. Обычно мы принимаем с
              назначением "Оплата по контракту №... за товар ...". Но в
              некоторых случаях можем принять платеж с назначением "Оплата по
              агентскому договору за оплату товара". Рубли обычно доходят на
              следующий день. Чтобы делать такие платежи у вас должен быть опыт
              в ВЭД, импорте, работе с НДС и тд. Платежи вы делаете на компанию,
              которая занимается экспортно-импортными операциями. Подробнее по
              данному направлению уточняйте у менеджера. Связаться с менеджером
              можно, нажав{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={handleHelp}
              >
                "Поддержка"
              </span>
              .
            </p>
            <p>
              Часто спрашивают, а как поставщик зачтет платеж в пользу клиента и
              не возникнет ли у него вопросов? Нет, не возникнет. Как правило,
              поставщикам не принципиально кто заплатит и откуда, главное чтобы
              деньги пришли. В подавляющем большинстве случаев это так. Но если
              нет, то мы можем подписать контракт, в котором мы будем
              фигурировать в роли платежного агента, где есть клиент, платежный
              агент и поставщик. Если у вас какой-то особый случай, можем его
              разобрать индивидуально. Связаться с менеджером можно, нажав{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={handleHelp}
              >
                "Поддержка"
              </span>
              .
            </p>
          </p>
        </div>
      ),
    },
    {
      title: "Что если перевод заблокирует банк",
      Content: (
        <div>
          <p>
            В случае, если банк заморозит или отклонит перевод, мы попробуем
            сделать повторный перевод с другого счета. Если банк не примет
            повторный перевод, то мы вернем 100% внесенной клиентом суммы. Но
            следует учитывать, что повторный перевод мы будем делать только в
            том, случае, если перевод не может быть принят банком получателя по
            нашей вине.  Поэтому, если случится такая ситуация, первым делом
            необходимо обратиться в службу поддержки банка, запросить статус
            операции и причины блокировки.
          </p>
        </div>
      ),
    },
    {
      title: "С какими странами мы не работаем",
      Content: (
        <div>
          <p>Украина, Северная Корея, Иран, ЮАР.</p>
        </div>
      ),
    },
    {
      title: "Принимаем/отправляем на брокерские счета?",
      Content: (
        <div>
          <p>Нет, с брокерскими счетами не работаем.</p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className={`questions-page-list`}>
        {questions?.map(({ title, Content }, index) => {
          return (
            <QuestionsAccordion key={index} title={title} content={Content} />
          );
        })}
      </div>
    </>
  );
};

export default QuestionsList;
