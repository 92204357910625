export const currencies = [
  'RUB',
  'USD',
  'EUR',
  'USDT',
  'AED',
  'CNY',
  'GBP',
  'TRY',
  'KRW',
  'JPY',
];
